import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Tyvärr fanns det inte så mycket här...</h2>
        <p>Var vänlig och prova en anna länk</p>
      </header>
    </article>
  </Layout>
);

export default IndexPage;
